<template>
    <div class="p-4 flex justify-between items-center">
        <div>
            <span>{{ data.name }}</span>
        </div>
        <div>
            <span>{{ data.relation }}</span>
        </div>
        <div class="flex items-center text-primary cursor-pointer hover:font-semibold whitespace-no-wrap" @click="callPatient(data.mid, data.pid)" v-if="data.online">
            <feather-icon class="mx-1" icon="VideoIcon" svgClasses="w-5 h-5"></feather-icon>
            <span>{{ $t('components.call.call') }}</span>
        </div>
        <div class="flex items-center text-grey whitespace-no-wrap" v-else>
            <feather-icon class="mx-1 invisible" icon="VideoIcon" svgClasses="w-5 h-5"></feather-icon>
            <span>{{ $t('components.call.offline') }}</span>
        </div>
    </div>
</template>
<script>
export default {
    props: {
        data: {
            type: Object,
            default: null,
            required: true,
        },
    },
    methods: {
        // 打給民眾
        callPatient(mid, pid) {
            const payload = { ring: 1, direction: 1 }
            payload.mid = mid
            payload.pid = pid
            payload.is_doctor = 0
            this.$store.dispatch('call_p2p', payload).finally(() => {
                if (this.$router.currentRoute.fullPath != '/emr2/' + pid + '/0') {
                    this.$router.push('/emr2/' + pid + '/0')
                }
            })
            this.$emit('call')
        },
    },
}
</script>
