<template>
    <div :id="'accordion-' + type" class="accordion">
        <div class="accordion__title" @click="toggleCollapse">
            <slot name="label"></slot>
            <div class="accordion__icons">
                <feather-icon class="accordion__icon--plus" icon="ChevronDownIcon" svgClasses="w-5 h-5"></feather-icon>
                <feather-icon class="accordion__icon--minus" icon="ChevronUpIcon" svgClasses="w-5 h-5"></feather-icon>
            </div>
        </div>
        <div class="accordion__content">
            <slot name="content"></slot>
        </div>
    </div>
</template>
<script>
export default {
    props: {
        type: {
            type: String,
            default: '',
            required: false,
        },
        defaultActive: {
            type: Boolean,
            default: false,
            required: false,
        },
    },
    mounted() {
        if (this.defaultActive) this.toggleCollapse()
    },
    methods: {
        // toggle開關
        toggleCollapse() {
            const target = document.getElementById('accordion-' + this.type)
            target.classList.toggle('is-active')
        },
    },
}
</script>
<style scoped>
.accordion {
    /* width: 300px; */
    padding: 0.25rem;
    border-bottom: 1px solid;
}

.accordion__title {
    display: flex;
    justify-content: space-between;
    align-items: center;
    cursor: pointer;
    font-size: 1rem;
    font-weight: 600;
    border-radius: 0.125rem;
    padding: 0.75rem;
}

/* .accordion__title:hover {
    --bg-opacity: 1;
    background-color: #dae1e7;
    background-color: rgba(218, 225, 231, var(--bg-opacity));
} */

.accordion__icons {
    /* border: 3px solid;
    border-color: transparent;
    border-radius: 50%; */
    width: 2rem;
    height: 2rem;
    display: grid;
    place-content: center;
    transition: 0.4s;
}

.accordion__icon--plus,
.accordion__icon--minus {
    grid-area: 1/1/2/2;
    transition: 0.4s ease-in-out;
    font-size: 1.2rem;
}

.accordion__icon--minus {
    opacity: 0;
    transform: rotate(-1turn);
}

.accordion__content {
    display: grid;
    grid-template-rows: 0fr;
    transition: 0.4s ease-in-out;
    /* background-color: #e0f7fa; */
    border-radius: 0.125rem;
}

.accordion__content > * {
    overflow: hidden;
}

/* active */
.accordion.is-active .accordion__content {
    grid-template-rows: 1fr;
}

.accordion.accordion.is-active .accordion__icons {
    border-color: var(--white);
}

.accordion.is-active .accordion__icon--plus {
    opacity: 0;
    transform: rotate(1turn);
}

.accordion.is-active .accordion__icon--minus {
    opacity: 1;
    transform: rotate(0turn);
}

.accordion.is-active .accordion__content > * {
    animation: overflowModify 0.5s ease-in-out forwards;
}

@keyframes overflowModify {
    0%,
    99.9% {
        overflow: hidden;
    }

    100% {
        overflow: visible;
    }
}
</style>
