<template>
    <div class="relative" style="min-height: 500px">
        <loadingSpinner :visible="isLoading" />
        <div v-if="data">
            <collapse :type="'patient'" :defaultActive="true" v-if="data.patients && data.patients.length > 0">
                <template v-slot:label>
                    <span>民眾與民眾家屬</span>
                </template>
                <template v-slot:content>
                    <div>
                        <callPatientItem :data="patient" v-for="patient in data.patients" :key="patient.pid" @call="closePopup"></callPatientItem>
                    </div>
                </template>
            </collapse>
            <collapse :type="'patientsEnterpriseDoctors-' + enterprise.eid" v-for="enterprise in data.patientsEnterpriseDoctors" :key="'patientsEnterpriseDoctors' + enterprise.eid">
                <template v-slot:label>
                    <span>[民眾單位-{{ enterprise.name }}] 醫事人員</span>
                </template>
                <template v-slot:content>
                    <div>
                        <!-- 在線 -->
                        <div class="py-4" v-if="hasOnlineInList(enterprise.doctors)">
                            <div class="mx-4 mb-2 font-semibold">
                                <span>{{ $t('navbar.Online') }}</span>
                            </div>
                            <callDoctorItem :data="doctor" :pid="parseInt(pid)" :qid="qid ? parseInt(qid) : parseInt(0)" :isTransfer="transfer" v-for="doctor in enterprise.doctors" :key="doctor.did" @call="closePopup" v-if="doctor.online"></callDoctorItem>
                        </div>
                        <!-- 離線 -->
                        <div class="py-4" v-if="!isAllOnlineInList(enterprise.doctors)">
                            <div class="mx-4 mb-2 font-semibold">
                                <span>{{ $t('navbar.Offline') }}</span>
                            </div>
                            <callDoctorItem :data="doctor" :pid="parseInt(pid)" :qid="qid ? parseInt(qid) : parseInt(0)" :isTransfer="transfer" v-for="doctor in enterprise.doctors" :key="doctor.did" @call="closePopup" v-if="!doctor.online"></callDoctorItem>
                        </div>
                    </div>
                </template>
            </collapse>
            <collapse :type="'contact'" v-if="!noContact && data.contacts && data.contacts.length > 0">
                <template v-slot:label>
                    <span>我的聯絡人</span>
                </template>
                <template v-slot:content>
                    <div>
                        <!-- 在線 -->
                        <div class="py-4" v-if="hasOnlineInList(data.contacts)">
                            <div class="mx-4 mb-2 font-semibold">
                                <span>{{ $t('navbar.Online') }}</span>
                            </div>
                            <callDoctorItem :data="contact" :pid="pid ? parseInt(pid) : parseInt(0)" :qid="qid ? parseInt(qid) : parseInt(0)" :isTransfer="transfer" v-for="contact in data.contacts" :key="contact.did" @call="closePopup" v-if="contact.online"></callDoctorItem>
                        </div>
                        <!-- 離線 -->
                        <div class="py-4" v-if="!isAllOnlineInList(data.contacts)">
                            <div class="mx-4 mb-2 font-semibold">
                                <span>{{ $t('navbar.Offline') }}</span>
                            </div>
                            <callDoctorItem :data="contact" :pid="pid ? parseInt(pid) : parseInt(0)" :qid="qid ? parseInt(qid) : parseInt(0)" :isTransfer="transfer" v-for="contact in data.contacts" :key="contact.did" @call="closePopup" v-if="!contact.online"></callDoctorItem>
                        </div>
                    </div>
                </template>
            </collapse>
            <collapse :type="'enterpriseDoctors-' + enterprise.eid" v-for="enterprise in data.enterpriseDoctors" :key="'enterpriseDoctors' + enterprise.eid" v-if="!noEnterprise">
                <template v-slot:label>
                    <span>[單位-{{ enterprise.name }}] 醫事人員</span>
                </template>
                <template v-slot:content>
                    <div>
                        <!-- 在線 -->
                        <div class="py-4" v-if="hasOnlineInList(enterprise.doctors)">
                            <div class="mx-4 mb-2 font-semibold">
                                <span>{{ $t('navbar.Online') }}</span>
                            </div>
                            <callDoctorItem :data="doctor" :pid="pid ? parseInt(pid) : parseInt(0)" :qid="qid ? parseInt(qid) : parseInt(0)" :isTransfer="transfer" v-for="doctor in enterprise.doctors" :key="doctor.did" @call="closePopup" v-if="doctor.online"></callDoctorItem>
                        </div>
                        <!-- 離線 -->
                        <div class="py-4" v-if="!isAllOnlineInList(enterprise.doctors)">
                            <div class="mx-4 mb-2 font-semibold">
                                <span>{{ $t('navbar.Offline') }}</span>
                            </div>
                            <callDoctorItem :data="doctor" :pid="pid ? parseInt(pid) : parseInt(0)" :qid="qid ? parseInt(qid) : parseInt(0)" :isTransfer="transfer" v-for="doctor in enterprise.doctors" :key="doctor.did" @call="closePopup" v-if="!doctor.online"></callDoctorItem>
                        </div>
                    </div>
                </template>
            </collapse>
            <collapse :type="'partnerDoctors'" v-if="!noPartner && data.partnerDoctors && data.partnerDoctors.length > 0">
                <template v-slot:label>
                    <span>{{ partner_name }}的醫事人員</span>
                </template>
                <template v-slot:content>
                    <div>
                        <!-- 在線 -->
                        <div class="py-4" v-if="hasOnlineInList(data.partnerDoctors)">
                            <div class="mx-4 mb-2 font-semibold">
                                <span>{{ $t('navbar.Online') }}</span>
                            </div>
                            <callDoctorItem :data="doctor" :pid="pid ? parseInt(pid) : parseInt(0)" :qid="qid ? parseInt(qid) : parseInt(0)" :isTransfer="transfer" v-for="doctor in data.partnerDoctors" :key="doctor.did" @call="closePopup" v-if="doctor.online"></callDoctorItem>
                        </div>
                        <!-- 離線 -->
                        <div class="py-4" v-if="!isAllOnlineInList(data.partnerDoctors)">
                            <div class="mx-4 mb-2 font-semibold">
                                <span>{{ $t('navbar.Offline') }}</span>
                            </div>
                            <callDoctorItem :data="doctor" :pid="pid ? parseInt(pid) : parseInt(0)" :qid="qid ? parseInt(qid) : parseInt(0)" :isTransfer="transfer" v-for="doctor in data.partnerDoctors" :key="doctor.did" @call="closePopup" v-if="!doctor.online"></callDoctorItem>
                        </div>
                    </div>
                </template>
            </collapse>
        </div>
    </div>
</template>
<script>
import { getCallList } from '@/api/user'
import collapse from '@/components/Common/collapse'
import callPatientItem from '@/components/Call/CallPatientItem'
import callDoctorItem from '@/components/Call/CallDoctorItem'
import loadingSpinner from '@/components/Common/loadingSpinner.vue'
export default {
    components: { collapse, callPatientItem, callDoctorItem, loadingSpinner },
    props: {
        pid: {
            type: String,
            default: '',
            required: false,
        },
        noContact: {
            type: Boolean,
            default: false,
            required: false,
        },
        noEnterprise: {
            type: Boolean,
            default: false,
            required: false,
        },
        noPartner: {
            type: Boolean,
            default: false,
            required: false,
        },
        qid: {
            type: String,
            default: '',
            required: false,
        },
        transfer: {
            type: Boolean,
            default: false,
            required: false,
        },
    },
    data() {
        return {
            data: null,
            isLoading: false,
        }
    },
    mounted() {
        this.isLoading = true
        this.getList()
    },
    sockets: {
        receive_message(msg) {
            if (msg) {
                switch (msg.entityType) {
                    // 變更醫師狀態
                    case 'doctor_status':
                        if (localStorage.getItem('di_tokenId')) this.getList()
                        break
                }
            }
        },
    },
    watch: {
        $props: {
            handler: 'getList',
            deep: true,
            // immediate: true,
        },
    },
    computed: {
        did() {
            return this.$store.getters.did
        },
        partner_id() {
            return this.$store.getters.partner
        },
        partnerlist() {
            return JSON.parse(localStorage.getItem('partnerlist'))
        },
        partner_name() {
            const selectedPartner = _.find(this.partnerlist, {
                id: this.partner_id.toString(),
            })
            return selectedPartner ? selectedPartner.company : ''
        },
    },
    methods: {
        // 取通話列表
        getList() {
            // this.isLoading = true
            // this.data = []
            const payload = {
                partner_id: this.partner_id,
                includeContact: !this.noContact,
                includeEnterprise: !this.noEnterprise,
                includePartner: !this.noPartner,
            }
            if (this.pid > 0) payload.pid = this.pid
            getCallList(payload)
                .then((res) => {
                    this.data = res.data.data
                })
                .finally(() => {
                    this.isLoading = false
                })
        },
        hasOnlineInList(list) {
            return list.some((person) => person.online)
        },
        isAllOnlineInList(list) {
            return list.every((person) => person.online === true)
        },
        closePopup() {
            this.$emit('closePopup')
        },
    },
}
</script>
